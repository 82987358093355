<script setup>
	// Libraries
	import { storeToRefs } from 'pinia';

	// Stores
	import { useGlobalStore } from '@/stores/global';
	const { generalInformation, donateDetails, socialLinks } = storeToRefs(useGlobalStore());

	import { useFooterStore } from '@/stores/footer';
	const { footerMenu, logo, copyright, links, additionalLinks } = storeToRefs(useFooterStore());

	// Props
	const { absoluteLinks } = defineProps({
		absoluteLinks: {
			type: Boolean,
			default: false,
		},
	});
</script>

<template>
	<footer class="global-footer" role="contentinfo">
		<FragmentsFooterRedBanner :links="socialLinks" :absoluteLinks="absoluteLinks" />

		<FragmentsHoursSpecialClosures />

		<FragmentsFooterWhiteBanner
			:logo="logo"
			:info="generalInformation"
			:donate="donateDetails"
			:absoluteLinks="absoluteLinks"
		/>

		<FragmentsFooterMultiColumnLinks :menu="footerMenu" :absoluteLinks="absoluteLinks" />

		<div class="links container">
			<ul class="left">
				<MessHtml v-if="copyright" :html="copyright" tag="p" />
				<li v-for="(link, linkIndex) in links" :key="linkIndex" class="bottom-link">
					<MessLink v-if="link?.link" :href="link.link">
						{{ link?.label }}
					</MessLink>
				</li>
			</ul>
			<ul class="right">
				<li v-for="(link, linkIndex) in additionalLinks" :key="linkIndex" class="bottom-link">
					<MessLink v-if="link?.link" :href="link.link">
						{{ link?.label }}
					</MessLink>
				</li>
			</ul>
		</div>
	</footer>
</template>

<style lang="scss">
	.global-footer {
		padding-bottom: 30px;
		background-color: var(--gray-100);

		.h2 {
			font-size: calc(20 / var(--base-fs) * 1rem);
			font-weight: 900;
			line-height: 1;
			letter-spacing: 0.3px;
			text-transform: uppercase;
		}

		p {
			font-size: calc(16 / var(--base-fs) * 1rem);
			font-weight: 350;

			@media (min-width: $tablet) {
				font-size: calc(14 / var(--base-fs) * 1rem);
			}
		}

		.links * {
			font-size: calc(12 / var(--base-fs) * 1rem);
			font-weight: 350;
			line-height: 1.56;
			letter-spacing: 0.18px;
		}

		.links {
			padding: 3rem 0 0 0;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			.left,
			.right {
				display: flex;
				gap: 2rem;
			}
			@media (min-width: $mobile) {
				flex-direction: row;
			}
		}
	}
</style>
